import React from 'react';
import './App.css';
import Layout from './components/Layout';
import LayoutAccount from './components/LayoutAccount';
import 'bootstrap/dist/css/bootstrap.css';
import Home from './components/Home';
import Register from './components/Register';
import Login from './components/Login';
import Logout from './components/Logout';
import Dashboard from './components/Dashboard';
import History from './pages/History';
import AddWords from './components/AddWords';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

export default function App() {
  return (
    <div className="appContainer">
      <Router>
        <Routes>
          <Route path="/" element={<Layout><Home /></Layout>} />
          <Route path="/Home" element={<Layout><Home /></Layout>} />
          <Route path="/Register" element={<Layout><Register /></Layout>} />
          <Route path="/Login" element={<Layout><Login /></Layout>} />
          <Route path="/Logout" element={<Layout><Logout /></Layout>} />
          <Route path="/Dashboard" element={<LayoutAccount><ProtectedRoute><Dashboard /></ProtectedRoute></LayoutAccount>} />
          <Route path="/AddWords" element={<LayoutAccount><ProtectedRoute><AddWords /></ProtectedRoute></LayoutAccount>} />
          <Route path="/History" element={<LayoutAccount><ProtectedRoute><History /></ProtectedRoute></LayoutAccount>} />
        </Routes>
      </Router>
    </div>
  );
}