const Footer = () => {
  return (
      <footer className='justify-content-between align-items-center'>
        <div className='col-md-4 align-items-center'>
          <span className='text-muted'>&#169; 2023 My3Words</span>
        </div>
        <ul className='nav col-md-4 justify-content-end list-unstyled'>
          <li className='ms-3'>
            <a className='text-muted' href='/'>
              <svg className='bi' width={24} height={24}>
                <use xlinkHref='#twitter' />
              </svg>
            </a>
          </li>
          <li className='ms-3'>
            <a className='text-muted' href='/'>
              <svg className='bi' width={24} height={24}>
                <use xlinkHref='#instagram' />
              </svg>
            </a>
          </li>
          <li className='ms-3'>
            <a className='text-muted' href='/'>
              <svg className='bi' width={24} height={24}>
                <use xlinkHref='#facebook' />
              </svg>
            </a>
          </li>
        </ul>
      </footer>
  )
}

export default Footer