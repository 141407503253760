import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

function DayFilterDropdown({ onChange }) {
  const [selectedDays, setSelectedDays] = useState('');

  const handleDaysDropdownChange = (event) => {
    const value = event.target.value;
    setSelectedDays(value);
    onChange(value);
  };

  const styles = {
    appearanceAuto: "auto", // overrides dropdowns not having down arrow
    dropdownColour: "#777" // overrides dropdowns font colour
  };

  return (
    <div>
      <Row>
        <Col sm="3">Days</Col>
        <Col sm="9">
          <select value={selectedDays} className="form-control" onChange={handleDaysDropdownChange} style={{ appearance: styles.appearanceAuto, color: styles.dropdownColour }}>
            <option value="60">Select Number of Days</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="5">5</option>
            <option value="7">Week</option>
            <option value="14">Fortnight</option>
            <option value="30">Month</option>
            <option value="60">2 Months</option>
            <option value="90">3 Months</option>
            <option value="180">6 Months</option>
            <option value="365">1 Year</option>
            <option value="730">2 Years</option>
            <option value="1095">3 Years</option>
            <option value="1825">5 Years</option>
            <option value="3650">10 Years</option>
          </select>
        </Col>
      </Row>
    </div>
  );
}

export default DayFilterDropdown;
