import 'bootstrap/dist/css/bootstrap.min.css'
import React, { useState, useRef } from 'react'
import { useForm } from "react-hook-form"
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import DatePickerWrapper from "./DatePickerWrapper"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import config from '../config'

export function Register() {
  const { register, getValues, watch, handleSubmit, formState: { errors }, clearErrors } = useForm();

  const [data, setdata] = useState({ email: '', password: '', forename: '', surname: '', country: '', region: '', town: '', selectedDate: '', gender: '' })

  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedGender, setSelectedGender] = useState();

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');

  // vs debug
  const apiUrl = config.registrationBaseURL;
/*  const apiUrl = "https://localhost:8001/api/registration/";*/


  const styles = {
    marginBottom: "1.5rem",
    errorColor: "red",
    appearanceAuto: "auto", // overrides dropdowns not having down arrow
    dropdownColour: "#777" // overrides dropdowns font colour
  };

  const maxLen = {
    forename: 20,
    surname: 30,
    email: 50,
    password: 30
  };

  const onSubmit = (formData) => {
    const data1 = { email: data.email, password: data.password, forename: data.forename, surname: data.surname, country: country, region: region, town: data.town, dob: selectedDate.toISOString(), token: "temp", gender: selectedGender };
    axios.post(apiUrl + "Register", data1)
      .then((result) => {
        if (result.data.Status === 'Invalid')
          alert('Invalid User');
        else if (result.data.Status === 'ExistingUser')
          alert('Email address already registered');
        else
          navigate('/Dashboard', { state: { data } });
      })
      .catch((error) => {
        if (error.response) {
          // The request was made, and the server responded with a status code outside the range of 2xx
          if (error.response.data.status === 'ExistingEmail') {
            setErrorMessage('Email address already registered');
          } else {
            // Handle other bad request errors here
          }
        } else if (error.request) {
          // The request was made, but no response was received
          setErrorMessage('An error occured. Please try again');
        } else {
          // Something happened in setting up the request that triggered an Error
          setErrorMessage('An error occured. Please try again');
        }
      });
  };

  const onChange = (e) => {
    e.persist();
    //debugger;
    setdata({ ...data, [e.target.name]: e.target.value });
  }

  function handleDateSelect(date) {
    setSelectedDate(date);
  }

  const handleGenderChange = (event) => {
    const value = event.target.value;
    setSelectedGender(value);
  };

  return (
    <div className="col-lg-8 offset-lg-2">
      <div className="card o-hidden border-0 shadow-lg my-5" style={{ "marginTop": "5rem" }}>
        <div className="card-body p-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="p-5">
                <div className="text-center">
                  <h1 className="h4 text-black mb-4">Please complete details below</h1>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="user">
                  <div className="form-group row" style={{ marginBottom: styles.marginBottom }}>
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label htmlFor="email">Email</label>
                      <input {...register("email", { required: true, maxLength: maxLen.email, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i })} type="text" name="email" onChange={onChange} value={data.Email} className="form-control" id="email" />
                      {errors.email && errors.email.type === "required" && <span style={{ color: styles.errorColor }}>Email is required</span>}
                      {errors.email && errors.email.type === "maxLength" && (
                        <span style={{ color: styles.errorColor }}>Email cannot exceed 50 characters</span>
                      )}
                      {errors.email && errors.email.type === "pattern" && <span>Invalid email</span>}
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="confirmEmail">Confirm Email</label>
                      <input {...register("confirmEmail", { required: true })} type="text" name="confirmEmail" onChange={onChange} className="form-control" id="confirmEmail" />
                      {errors.confirmEmail && errors.confirmEmail.type === "required" && <span style={{ color: styles.errorColor }}>Please confirm your email</span>}
                      {watch("confirmEmail") !== watch("email") &&
                        getValues("confirmEmail") ? (
                        <span style={{ color: styles.errorColor }}>Emails do not match</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row" style={{ marginBottom: styles.marginBottom }}>
                    <div className="col-sm-6">
                      <label htmlFor="password">Password</label>
                      <input {...register("password", { required: true, minLength: 8, maxLength: maxLen.password })} id="password" type="Password" name="password" onChange={onChange} value={data.password} className="form-control" />
                      {errors.password && errors.password.type === "required" && <span style={{ color: styles.errorColor }}>Password field is required</span>}
                      {errors.password && errors.password.type === "minLength" && (
                        <span style={{ color: styles.errorColor }}>Password cannot less than 8 characters</span>
                      )}
                      {errors.password && errors.password.type === "maxLength" && (
                        <span style={{ color: styles.errorColor }}>Password cannot exceed 30 characters</span>
                      )}
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="confirmPassword">Confirm Password</label>
                      <input  {...register("confirmPassword", { required: true })} type="Password" name="confirmPassword" onChange={onChange} className="form-control" id="confirmPassword" />
                      {errors.confirmPassword && errors.confirmPassword.type === "required" && <span style={{ color: styles.errorColor }}>Please confirm your password</span>}
                      {watch("confirmPassword") !== watch("password") &&
                        getValues("confirmPassword") ? (
                        <span style={{ color: styles.errorColor }}>Passwords do not match</span>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group row" style={{ marginBottom: styles.marginBottom }}>
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label htmlFor="forename">Forename</label>
                      <input {...register("forename", { required: true, maxLength: maxLen.forename })} type="text" name="forename" onChange={onChange} value={data.forename} className="form-control" id="forename" />
                      {errors.forename && errors.forename.type === "required" && <span style={{ color: styles.errorColor }}>Forename is required</span>}
                      {errors.forename && errors.forename.type === "maxLength" && (
                        <span style={{ color: styles.errorColor }}>Forename cannot exceed 20 characters</span>
                      )}
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="surname">Surname</label>
                      <input {...register("surname", { required: true, maxLength: maxLen.surname })} type="text" name="surname" onChange={onChange} value={data.surname} className="form-control" id="surname" />
                      {errors.surname && errors.surname.type === "required" && <span style={{ color: styles.errorColor }}>Surname is required</span>}
                      {errors.surname && errors.surname.type === "maxLength" && (
                        <span style={{ color: styles.errorColor }}>Surname cannot exceed 30 characters</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row" style={{ marginBottom: styles.marginBottom }}>
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label htmlFor="country">Country (optional)</label>
                      <CountryDropdown value={country} onChange={(val) => setCountry(val)} id="country" name="country" className="form-control" defaultOptionLabel="Select Country" style={{ appearance: styles.appearanceAuto, color: styles.dropdownColour }} />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="region">Region (optional)</label>
                      <RegionDropdown country={country} value={region} onChange={(val) => setRegion(val)} blankOptionLabel="Region (select country first)" name="region" id="region" className="form-control" defaultOptionLabel="Select Region (optional)" style={{ appearance: styles.appearanceAuto, color: styles.dropdownColour }} />
                    </div>
                  </div>
                  <div className="form-group row" style={{ marginBottom: styles.marginBottom }}>
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <label htmlFor="town">Town (optional)</label>
                      <input type="text" onChange={onChange} value={data.town} className="form-control" id="town" name="town" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="dob">Date of Birth (optional)</label>
                      <DatePickerWrapper onDateSelect={handleDateSelect} id="dob" />
                    </div>
                    <div className="col-sm-3">
                      <label htmlFor="gender">Gender (optional)</label>
                      <select value={selectedGender} onChange={handleGenderChange} className="form-control" id="gender" name="gender" style={{ appearance: styles.appearanceAuto }}>
                        <option value="">Select Gender</option>
                        <option value="m">Male</option>
                        <option value="f">Female</option>
                        <option value="n">Non-Binary</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group row" style={{ marginBottom: styles.marginBottom }}>
                    <script src="https://www.google.com/recaptcha/api.js" async defer></script>
                    <div className="g-recaptcha" data-sitekey="6LdOA3olAAAAANILr-iaK2p_pfuAWm1C07kmxkcq"></div>
                  </div>
                  <button type="submit" className="btn btn-main btn-block" style={{ marginBottom: styles.marginBottom }}>
                    Register
                  </button>
                  <div className="form-group row">
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                  </div>
                  <hr />
                </form>
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register