import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import WordCloud from './WordCloud';
import config from '../config'
import { Col, Row, Collapse } from 'reactstrap';

const WordList = (props) => {
  let [words, setWords] = useState([]);

  // vs debug
  const apiUrl = config.wordsBaseURL;
  /*  const apiUrl = "https://localhost:7174/api/words/";*/

  const token = localStorage.getItem('authToken');
  const userID = localStorage.getItem('userID') != null ? localStorage.getItem('userID') : 0;
  const methodName = localStorage.getItem('userID') != null ? "GetUserWords" : "GetWordsAll";

  const data1 = { userID: userID, numDays: props.numDays, countryID: props.countryID, regionID: props.regionID, town: props.town, gender: props.gender, ageRange: props.ageRange.join(",") };

  const containerRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  //const handleDetails = (value) => {
  //  setIsFiltersLinkDisplayed(value);
  //}

  //const closeDetails = async () => {
  //  setIsFiltersLinkDisplayed(true);
  //}

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiUrl + methodName, {
          params: data1,
          headers: {
            Authorization: localStorage.getItem('userID') != null ? `Bearer ${token}` : 'none',
          },
        });

        const data = response.data;

        // Object to store the count of unique items
        const itemCounts = {};

        // Loop through the data
        for (const item of data) {
          // Loop through the keys in each JSON object (e.g., word1, word2, word3)
          for (const key in item) {
            // Check if the item value exists in itemCounts, if not, initialize it to 0
            if (!itemCounts.hasOwnProperty(item[key])) {
              itemCounts[item[key]] = 0;
            }
            // Increment the count for the item value
            itemCounts[item[key]]++;
          }
        }

        // Create an array of unique items and their counts
        const uniqueItemsWithCounts = [];
        for (const item in itemCounts) {
          uniqueItemsWithCounts.push({ text: item + ":" + itemCounts[item], size: itemCounts[item] });
        }

        // Sort the array by count in descending order
        setWords(uniqueItemsWithCounts);

      } catch (error) {
        console.error('Error fetching words:', error);
      }
    };

    fetchData();

    if (containerRef.current) {
      const containerRect = containerRef.current.parentNode.parentNode.parentNode.parentNode.parentNode.getBoundingClientRect();
      setDimensions({ width: window.innerWidth - containerRect.left, height: window.innerHeight - containerRect.top });
    }
  }, [props]);

  // Sort words by occurrences (ascending order)
  words = words.sort((a, b) => b.size - a.size);
  
  return (
    <Row>
      <div ref={containerRef}>
        <WordCloud dataWords={words} dimensions={dimensions} token={token} userID={userID} numDays={props.numDays} />
      </div>
    </Row>
    //<div ref={containerRef} style={{ display: 'flex', height: '100%', width: '100%' }}>
    //  <WordCloud dataWords={words} dimensions={dimensions} token={token} userID={userID} numDays={props.numDays} />
    //</div>
  );
};

export default WordList;
