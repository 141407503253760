const config = {
  wordsBaseURL: "https://api.words.my3words.life/api/words/",  // live
  locationBaseURL: "https://api.user.my3words.life/api/location/",  // live
  loginBaseURL: "https://api.user.my3words.life/api/login/",  // live
  registrationBaseURL: "https://api.user.my3words.life/api/registration/",  // live
  verificationBaseURL: "https://api.user.my3words.life/api/verification/",  // live

  //wordsBaseURL: "https://localhost:7174/api/words/",  // dev
  //locationBaseURL: "https://localhost:7005/api/location/",  // dev
  //loginBaseURL: "https://localhost:7005/api/login/",  // dev
  //registrationBaseURL: "https://localhost:7005/api/registration/",  // dev
  //verificationBaseURL: "https://localhost:7005/api/verification/",  // dev
};

export default config;