import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import { useNavigate } from 'react-router-dom';

export function Logout(props) {
  localStorage.removeItem("authToken");
  localStorage.removeItem("userID");
  localStorage.removeItem("forename");
  localStorage.removeItem("surname");

  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return null
}

export default Logout