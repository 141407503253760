import React, { useRef, useState, useEffect } from 'react';
import WordList from './WordList';
import SlideDrawer from "./SlideDrawer";
import axios from 'axios';
import config from '../config'
import { Col, Row, Collapse } from 'reactstrap';
import LogoutAfterInactivity from './LogoutAfterInactivity';

export function Dashboard(props) {
  const token = localStorage.getItem('authToken');
  const userID = localStorage.getItem('userID');
  const email = localStorage.getItem('email');

  const [errorMessage, setErrorMessage] = useState('');
  const [returnMessage, setReturnMessage] = useState('');

  const forename = localStorage.getItem('forename');
  const surname = localStorage.getItem('surname');

  const [verified, setVerified] = useState(localStorage.getItem('verified') === 'true');

  const divRef = useRef(null);
  const [dimensions1, setDimensions1] = useState({ width: 0, height: 0 });

  const [selectedDays, setSelectedDays] = useState(60);
  const [selectedCountry, setSelectedCountry] = useState("worldwide");
  const [selectedRegion, setSelectedRegion] = useState("notSelected");
  const [town, setTown] = useState("noTown");
  const [gender, setGender] = useState("b");
  let [ageRange, setAgeRange] = useState([0, 100]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  //const [isFiltersLinkDisplayed, setIsFiltersLinkDisplayed] = useState("inline");

  const [drawerOpen, setDrawerOpen] = useState(false);

  function handleOpenDrawerButton() {
    setDrawerOpen(!drawerOpen);
    setIsFiltersOpen(!isFiltersOpen);
  }

  //const handleDetails = (value) => {
  //  setIsFiltersLinkDisplayed(value);
  //}

  //const closeDetails = async () => {
  //  setIsFiltersLinkDisplayed(true);
  //}

  const handleDaysChange = (value) => {
    setSelectedDays(value);
  };

  const handleCountryDropdownChange = (value) => {
    if (value == "") {
      value = "worldwide";
    }

    setSelectedCountry(value);
  };

  const handleRegionDropdownChange = (value) => {
    if (value == "") {
      value = "";
    }

    setSelectedRegion(value);
  };

  const handleTownDropdownChange = (value) => {
    if (value != "Enter Town (after Region)") {
      setTown(value);
    }
  };

  const handleGenderDropdownChange = (value) => {
    if (value != "b") {
      setGender(value);
    }
  };

  const handleAgeChange = (value) => {
    ageRange = value;
  };

  const handleAgeChangeCommitted = (newValue) => {
    setAgeRange(newValue);
  };

  // vs debug
  const apiUrl = config.registrationBaseURL + "SendEmail?email=" + email + "&header=Register&forename=" + forename + "&userID=" + userID + "&VerificationGUID=void";
  /*  const apiUrl = "https://localhost:7005/api/registration/";*/

  const handleEmailClick = () => {
    axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      setReturnMessage("Email sent. Please logout and use the link in the email for verification");
    })
    .catch((error) => {
      console.log(error);
      if (error.response) {
        // The request was made, but no response was received
        setReturnMessage('An error occured. Please try again');
      } else {
        // Something happened in setting up the request that triggered an Error
        setReturnMessage('An error occured. Please try again');
      }
    });
  }

  useEffect(() => {
    if (verified === true && divRef.current) {
      const containerRect = divRef.current.getBoundingClientRect();
      console.log(window.innerWidth - containerRect.width);
      console.log(window.innerHeight - containerRect.height);
      setDimensions1({ width: window.innerWidth - containerRect.width, height: window.innerHeight - containerRect.height });
    }
  }, []);

  return (
    <Row className="mt-5">
      <Col xs="12" sm="10" className="mt-3 text-center">
        <Row>
          <Col>
            <SlideDrawer show={drawerOpen} handleDaysDropdownChange={handleDaysChange} handleCountryDropdownChange={handleCountryDropdownChange} handleRegionDropdownChange={handleRegionDropdownChange} handleTownDropdownChange={handleTownDropdownChange} handleGenderDropdownChange={handleGenderDropdownChange} handleAgeChange={handleAgeChange} handleAgeChangeCommitted={handleAgeChangeCommitted} handleAgeChangeCommitted={handleAgeChangeCommitted} />
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6">
            <LogoutAfterInactivity />
            <h1>Welcome back {forename} {surname}</h1>
          </Col>
          <Col xs="12" sm="6">
            <h3>The top 100 words for {selectedRegion != "notSelected" ? selectedRegion + "," : ""} {selectedCountry != "worldwide" ? selectedCountry : selectedCountry} in the last {selectedDays} days</h3>
          </Col>
        </Row>
        {verified === true && (
          <Row>
            <Col xs="12" sm="6">
              To add words for the day, please click on "Add Words"<br />
              To see your word history, please click on "History"
            </Col>
            <Col xs="12" sm="6">
              <span className="underline clickable" onClick={handleOpenDrawerButton}>{isFiltersOpen ? 'hide filters' : 'show filters'}</span>
            </Col>
          </Row>
        )}
        {verified === true && (
          <div ref={divRef}>
          <Row>
            <Col xs="12">
              <WordList numDays={selectedDays} countryID={selectedCountry} regionID={selectedRegion} town={town} gender={gender} ageRange={ageRange} />
            </Col>
            </Row>
          </div>
        )}
        {verified === false && (
          <Row>
            <Col xs="12">
              Your account is unverified at the moment.<br />
              Please click on the link which was sent in your welcome email. Check your SPAM folder in case it has got caught in there.<br />
              Or click <span className="clickable underline" onClick={handleEmailClick}>here</span> to resend the welcome email.
              <br /><br />
              <div className="errorMessage">
                { returnMessage }
              </div>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  )
}

export default Dashboard