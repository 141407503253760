import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';

function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const isAuthenticated = localStorage.getItem('authToken');

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [navigate]);

  return isAuthenticated ? children : null;
}

export default ProtectedRoute;
