import React, { useState, useEffect, useRef } from 'react';
import { DayPilot, DayPilotMonth } from '@daypilot/daypilot-lite-react';
import './Calendar.css';
import axios from 'axios';
import moment from 'moment';
import config from '../config'

const Calendar = () => {
  let calendarRef = useRef();
  const [startDate, setStartDate] = useState(DayPilot.Date.today());
  const [errorMessage, setErrorMessage] = useState('');

  const calendar = () => {
    return calendarRef.current.control;
  };

  const onPreviousMonth = () => {
    const previousMonth = startDate.addMonths(-1).firstDayOfMonth();
    setStartDate(previousMonth);
  };

  const onNextMonth = () => {
    const nextMonth = startDate.addMonths(1).firstDayOfMonth();
    setStartDate(nextMonth);
  };

  const addDays = (date, numDays) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + numDays);
    return formatDate(newDate);
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const parseDateString = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(year, month - 1, day);
  };

  let clonedElement;

  const handleMouseOver = (event) => {
    let target = event.target;

    clonedElement = target.cloneNode(true);

    let childDiv = clonedElement.querySelector('div[style*="display:none"], div[style*="display: none"]');

    if (childDiv) {
      childDiv.style.display = "block";
      childDiv.style.color = clonedElement.id.toString().indexOf("am") > -1 ? "#a8dadc" : "#e63946";
    }

    clonedElement.innerHTML = clonedElement.innerHTML.toString().replace("Y<br>", "");

    const thirds = (window.innerWidth / 3);

    // Set cloned element style properties for absolute positioning
    clonedElement.style.position = 'absolute';
    clonedElement.style.top = `0px`;
    clonedElement.style.left = `0px`;
    clonedElement.style.backgroundColor = '#fff';
    clonedElement.style.fontSize = '15px';
    clonedElement.style.width = thirds + 'px';
    clonedElement.style.marginLeft = thirds + 'px';

    // Append the cloned element to the body or any container you prefer
    document.body.appendChild(clonedElement);
  };

  const handleMouseOut = (event) => {
    if (clonedElement) {
      clonedElement.remove();
    }
  };

  const searchByEntryDate = (array, targetDate, ampm) => {
    return array.filter((item) => {
      const itemDate = parseDateString(item.formattedEntryDate);
      const searchDate = parseDateString(targetDate);
      const entryPeriod = item.entryPeriod;

      return (
        entryPeriod === ampm &&
        itemDate.getDate() === searchDate.getDate() &&
        itemDate.getMonth() === searchDate.getMonth() &&
        itemDate.getFullYear() === searchDate.getFullYear()
      );
    });
  };

  // vs debug
  const apiUrl = config.wordsBaseURL;
  /*  const apiUrl = "https://localhost:7174/api/words/";*/

  const token = localStorage.getItem('authToken');
  const userID = localStorage.getItem('userID');

  useEffect(() => {
    if (calendarRef.current) {
      let startDate = calendar().visibleStart();
      console.log('Visible start:', startDate);
    }

    let i = 0;
    let j = 0;

    let amStr = "";
    let pmStr = "";

    processCalendar();
  }, [startDate]);

  const processCalendar = () => {
    let startDate = calendar().visibleStart();
    const end = calendar().visibleEnd();

    const data1 = { userID: userID, startDate: startDate, endDate: end };

    axios.get(apiUrl + "GetCalendarHistory", {
      params: data1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      const resultsArr = result.data;
      let i = 0;
      let j = 0;

      let amStr = "";
      let pmStr = "";

      document.querySelectorAll(".month_default_cell_inner").forEach(form => {
        var dateSearchResultsAm = searchByEntryDate(resultsArr, addDays(startDate, i), "am");
        var dateSearchResultsPm = searchByEntryDate(resultsArr, addDays(startDate, i), "pm");

        var newElem = document.createElement('div');

        if (dateSearchResultsAm.length > 0) {
          if (window.innerWidth < 800) {
            amStr = dateSearchResultsAm[0].entryPeriod.toUpperCase() + "<br />Y<br /><div style='display:none;'>" + dateSearchResultsAm[0].word1 + "<br />" + dateSearchResultsAm[0].word2 + "<br />" + dateSearchResultsAm[0].word3 + "</div>";
          }
          else {
            amStr = dateSearchResultsAm[0].entryPeriod.toUpperCase() + "<br />" + dateSearchResultsAm[0].word1 + "<br />" + dateSearchResultsAm[0].word2 + "<br />" + dateSearchResultsAm[0].word3;
          }
        }
        else {
          amStr = window.innerWidth < 800 ? "AM<br />X" : "AM<br />Missed";
        }

        if (dateSearchResultsPm.length > 0) {
          if (window.innerWidth < 800) {
            pmStr = dateSearchResultsPm[0].entryPeriod.toUpperCase() + "<br />Y<br /><div style='display:none;'>" + dateSearchResultsPm[0].word1 + "<br />" + dateSearchResultsPm[0].word2 + "<br />" + dateSearchResultsPm[0].word3 + "</div>";
          }
          else {
            pmStr = dateSearchResultsPm[0].entryPeriod.toUpperCase() + "<br />" + dateSearchResultsPm[0].word1 + "<br />" + dateSearchResultsPm[0].word2 + "<br />" + dateSearchResultsPm[0].word3;
          }
        }
        else {
          pmStr = window.innerWidth < 800 ? "PM<br />X" : "PM<br />Missed";
        }

        if (dateSearchResultsAm.length > 0 || dateSearchResultsPm.length > 0) {
          newElem.className = "addedDiv col-lg-12";

          var amDiv = document.createElement('div');
          amDiv.className = 'addedDivDiv square border-end border-1 blueText hover-text';
          amDiv.id = addDays(startDate, i) + 'am';
          amDiv.innerHTML = amStr;
          amDiv.onmouseover = handleMouseOver;
          amDiv.onmouseout = handleMouseOut;

          newElem.appendChild(amDiv);

          var pmDiv = document.createElement('div');
          pmDiv.className = 'addedDivDiv redText hover-text';
          pmDiv.id = addDays(startDate, i) + 'pm';
          pmDiv.innerHTML = pmStr;
          pmDiv.onmouseover = handleMouseOver;
          pmDiv.onmouseout = handleMouseOut;

          newElem.appendChild(pmDiv);

          form.appendChild(newElem);
        }
        else {
          const missedTextAm = window.innerWidth < 800 ? "AM<br />X" : "AM<br />Missed";
          const missedTextPm = window.innerWidth < 800 ? "PM<br />X" : "PM<br />Missed";

          newElem.className = "addedDiv col-lg-12";
          newElem.innerHTML = "<div class='addedDivDiv square border-end border-1 blueText'>" + missedTextAm + "</div><div class='addedDivDiv redText'>" + missedTextPm + "</div>";
          form.appendChild(newElem);
        }

        i = i + 7;

        let d1 = moment(addDays(startDate, i), "DD-MM-YYYY");
        let d2 = moment(new Date(end), "DD-MM-YYYY");

        if (d1.isAfter(d2) || d1.isSame(d2)) {
          j = j + 1;

          i = j;
        }

        amStr = "";
        pmStr = "";
        newElem = null;
      });
    })
    .catch((error) => {
      console.log(error);
      if (error.response) {
        // The request was made, but no response was received
        setErrorMessage('An error occured. Please try again');
      } else {
        // Something happened in setting up the request that triggered an Error
        setErrorMessage('An error occured. Please try again');
      }
    });
  }

  const calendarConfig = {
    startDate: startDate,
    viewType: 'Month',
    ref: calendarRef,
  };

  return (
    <div>
      <button onClick={onPreviousMonth}>Previous Month</button>
      <button onClick={onNextMonth}>Next Month</button>
      <DayPilotMonth key={startDate.toString()} {...calendarConfig} />
    </div>
  );
};

export default Calendar;