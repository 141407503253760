import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import axios from 'axios';
import config from '../config'

function TownFilterDropdown({ onChange, country, region }) {
  const [town, setTown] = useState('none');
  const [towns, setTowns] = useState([]);

  const handleTownFilterChange = (event) => {
    const value = event.target.value;
    setTown(value);
    onChange(value);
  };

  const styles = {
    marginBottom: "1.5rem",
    errorColor: "red",
    appearanceAuto: "auto", // overrides dropdowns not having down arrow
    dropdownColour: "#777" // overrides dropdowns font colour
  };

  const apiUrl = config.locationBaseURL;
  //const token = localStorage.getItem('authToken');

  useEffect(() => {
    const apiUrl = config.locationBaseURL;

    const fetchTowns = async () => {
      try {
        const response = await axios.get(apiUrl + "GetTowns?country=" + country + "&region=" + region, {
        });

        const data = response.data;
        const townOptions = data.map((town) => ({
          value: town,
          label: town
        }));

        setTowns(townOptions);
      } catch (error) {
        console.error(error);
        // handle error
      }
    }

    fetchTowns();
  }, [country, region]);

  return (
    <div>
      <Row>
        <Col sm="3">Town</Col>
        <Col sm="9">
          <select onChange={handleTownFilterChange} value={town} className="form-control" style={{ appearance: styles.appearanceAuto, color: styles.dropdownColour }}>
            <option value="none">Select (after Region)</option>
            {towns.map((townOption, index) => (
              <option key={index} value={townOption.value}>{townOption.label}</option>
            ))}
          </select>
        </Col>
      </Row>
    </div>
  );
}

export default TownFilterDropdown;