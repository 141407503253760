import React, { useRef, useEffect, useState } from 'react';
import { Col, Row, Collapse } from 'reactstrap';
import Helper from '../helper';
import * as d3 from 'd3';
import cloud from 'd3-cloud';
import axios from 'axios';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import config from '../config'

const WordCloud = ({ dataWords, dimensions, token, userID, numDays }) => {
  const svgRef = useRef();
  const wordCloudContainerRef = useRef();
  let multiplier = 0;
  const adjuster = window.innerWidth < 768 ? 1.8 : 1.4;

  multiplier = dataWords.length > 0 ? dataWords[0].size <= 7 ? window.innerWidth < 768 ? 10 : 18 : 0 : 0;

  if (multiplier === 0) {
    multiplier = dataWords.length > 0 ? dataWords[0].size <= 8 ? window.innerWidth < 768 ? 8 : 15 : 0 : 0;
  }
  if (multiplier === 0) {
    multiplier = dataWords.length > 0 ? dataWords[0].size <= 9 ? window.innerWidth < 768 ? 7 : 13 : 0 : 0;
  }
  if (multiplier === 0) {
    multiplier = dataWords.length > 0 ? dataWords[0].size <= 10 ? window.innerWidth < 768 ? 6 : 11 : 0 : 0;
  }
  if (multiplier === 0) {
    multiplier = dataWords.length > 0 ? dataWords[0].size <= 11 ? window.innerWidth < 768 ? 5 : 10 : 0 : 0;
  }
  if (multiplier === 0) {
    multiplier = dataWords.length > 0 ? dataWords[0].size <= 12 ? window.innerWidth < 768 ? 4 : 9 : 0 : 0;
  }
  if (multiplier === 0) {
    multiplier = dataWords.length > 0 ? dataWords[0].size <= 13 ? window.innerWidth < 768 ? 3 : 8 : 0 : 0;
  }
  if (multiplier === 0) {
    multiplier = dataWords.length > 0 ? dataWords[0].size <= 14 ? window.innerWidth < 768 ? 2 : 7 : 0 : 0;
  }
  if (multiplier === 0) {
    multiplier = dataWords.length > 0 ? dataWords[0].size <= 15 ? window.innerWidth < 768 ? 1 : 6 : 0 : 0;
  }
  if (multiplier === 0) {
    multiplier = dataWords.length > 0 ? dataWords[0].size <= 16 ? window.innerWidth < 768 ? 1 : 5 : 0 : 0;
  }
  if (multiplier === 0) {
    multiplier = dataWords.length > 0 ? dataWords[0].size <= 17 ? window.innerWidth < 768 ? 1 : 4 : 0 : 0;
  }
  if (multiplier === 0) {
    multiplier = dataWords.length > 0 ? dataWords[0].size <= 18 ? window.innerWidth < 768 ? 1 : 3 : 0 : 0;
  }
  if (multiplier === 0) {
    multiplier = dataWords.length > 0 ? dataWords[0].size <= 19 ? window.innerWidth < 768 ? 1 : 2 : 0 : 0;
  }
  if (multiplier === 0) {
    multiplier = dataWords.length > 0 ? dataWords[0].size <= 20 ? window.innerWidth < 768 ? 1 : 1 : 0 : 0;
  }

  const [popup, setPopup] = useState({ show: false, x: 0, y: 0, wordText: '', wordCount: 0 });

  const cloudRef = useRef();
  const moreDetailsDivRef = useRef();
  const [moreDetailsClassName, setMoreDetailsClassName] = useState("hiddenDiv");
  const [cloudRefClassName, setCloudRefClassName] = useState("DGdflex");
  const [moreDetailsHeader, setMoreDetailsHeader] = useState("More Details");
  const [statsHeader, setStatssHeader] = useState("Stats");
  const [wordCount, setWordCount] = useState(0);
  const [allUserWordCount, setAllUserWordCount] = useState(0);
  const [allWorldWordCount, setAllWorldWordCount] = useState(0);
  const [totalWorldWordTextCount, setTotalWorldWordTextCount] = useState(0);
  const [lastWordUsedWorldDetails, setLastWordUsedWorldDetails] = useState("");

  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [town, setTown] = useState("");

  const [genderAge, setGenderAge] = useState("");

  const moreDetailsRef = useRef();

  const [isFiltersLinkDisplayed, setIsFiltersLinkDisplayed] = useState("inline");

  // Define rows and columns state variables
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  const [widthOffset, setWidthOffset] = useState(window.innerWidth <= 768 ? 2 : 2.3);

  function closeDetailsDiv() {
    setMoreDetailsClassName(Helper.removeClassAndAddNew(moreDetailsClassName, "DGdflex", "hiddenDiv"));
    setCloudRefClassName(Helper.removeClassAndAddNew(cloudRefClassName, "hiddenDiv", "DGdflex"));
    closePopup();
  }

  const handleDetails = (value) => {
    setIsFiltersLinkDisplayed(value);
  }

  const closeDetails = async () => {
    setIsFiltersLinkDisplayed(true);
  }

  const handleMoreDetails = async (wordText) => {
    handleDetails("none");
    setMoreDetailsHeader("Your occurences of the word '" + wordText + "'");
    setStatssHeader("Stats for the word '" + wordText + "'");

    const apiUrl = config.wordsBaseURL;

    const methodName = localStorage.getItem('userID') != null ? "GetWordMoreDetails?userID=" + userID + "&wordText=" + wordText + "&numDays=" + numDays : "GetWordMoreDetailsPublic?wordText=" + wordText + "&numDays=" + numDays;

    try {
      const response = await axios.get(apiUrl + methodName, {
        headers: {
          Authorization: localStorage.getItem('userID') != null ? `Bearer ${token}` : 'none',
        },
      });

      setMoreDetailsClassName(Helper.removeClassAndAddNew(moreDetailsClassName, "hiddenDiv", "DGdflex"));
      setCloudRefClassName(Helper.removeClassAndAddNew(cloudRefClassName, "DGdflex", "hiddenDiv"));

      const data = response.data;

      // Assuming that the response is an array of objects and each object is a row
      setRows(data);

      setWordCount(data.length);
      setAllUserWordCount(data[0].totalUserWordCount);
      setAllWorldWordCount(data[0].totalWorldWordCount);
      setTotalWorldWordTextCount(data[0].totalWorldWordTextCount);

      const splitValues = data[0].lastWordUsedWorldDetails.split(";");
      const luw_date = splitValues[0].split(" ")[0];
      const luw_period = splitValues[1] == "am" ? " in the morning." : " in the afternoon";
      setCountry(splitValues[4] != "null" ? "Country was " + splitValues[4] : "");
      setRegion(splitValues[3] != "null" ? "Region was " + splitValues[3] : "");
      setTown(splitValues[2] != "null" ? "Town was " + splitValues[2] : "");

      const age = splitValues[6] != "null" ? splitValues[6] + " year old" : "";
      const gender = splitValues[5] === "m" ? " Male" : splitValues[5] === "f" ? " Female" : " Non-Binary";

      setGenderAge("By a " + age + gender);

      setLastWordUsedWorldDetails(Helper.convertToPrettyDate(luw_date) + luw_period);

    } catch (error) {
      console.error(error);
      // handle error
    }
  };

  const handleClick = (wordText, wordCount) => (event) => {
    let x, y;
    if (event.changedTouches) { // check if this is a touch event
      x = event.changedTouches[0].clientX;
      y = event.changedTouches[0].clientY;
    } else { // if not, it's a mouse event
      x = event.clientX;
      y = event.clientY;
    }

    setPopup({
      show: true,
      x,
      y,
      wordText,
      wordCount
    });
  };

  const closePopup = () => {
    setPopup(prevPopup => ({ ...prevPopup, show: false }));
  };

  const statsData = [
    createData('Count', rows.length),
  ];

  function createData(
    name: string,
    count: number,
  ) {
    return { name, count };
  }

  useEffect(() => {
    // Set DataGrid columns
    setColumns([
      { field: 'entryDate', headerName: 'Date', width: 150 },
      { field: 'entryPeriod', headerName: 'am/pm', width: 150 },
      { field: 'wordsThatPeriod', headerName: 'Words', width: 400 },
    ]);

    // if you click anywhere in the document except the "more details" link in the popup box
    const closePopupIfClickedOutside = (event) => {
      if (popup.show === false && event.target.className !== "underline") {
        closePopup();
      }
    };

    // Add the event listener
    document.addEventListener('mousedown', closePopupIfClickedOutside);

    const drawWordCloud = (words, width, height) => {
      const svg = d3.select(svgRef.current)
        .attr('width', width)
        .attr('height', height);

      // Clear the SVG by removing all its child elements
      svg.selectAll('*').remove();

      const wordCloudLayout = cloud()
        .size([width,height])
        .words(words)
        .padding(3)
        .rotate(() => Math.floor(Math.random() * 2) * 90)
        .font('Impact')
        .fontSize((d) => d.size * multiplier * adjuster)
        .on('end', draw);

      wordCloudLayout.start();

      function draw(words) {
        const fillColor = d3.scaleOrdinal(d3.schemeCategory10);

        const wordGroup = svg.append('g')
          .attr('transform', `translate(${width / widthOffset}, ${height / 2})`)
          .selectAll('text')
          .data(words)
          .enter().append('text')
          .style('font-size', (d) => `${d.size}px`)
          .style('font-family', 'Impact')
          .style('fill', (d, i) => fillColor(i))
          .style('cursor', 'help')
          .attr('text-anchor', 'middle')
          .attr('transform', (d) => `translate(${[d.x, d.y]})rotate(${d.rotate})`)
          .attr('count', (d) => d.text.split(":")[1])
          .attr('class', 'wordText')
          .text((d) => d.text.split(":")[0])

        if (wordCloudContainerRef.current) {
          const elements = wordCloudContainerRef.current.getElementsByClassName('wordText');

          Array.from(elements).forEach((element) => {
            element._clickHandler = (event) => handleClick(element.textContent, element.attributes.count.value)(event);
            element.addEventListener('click', element._clickHandler);
          });

          // Cleanup function to remove event listeners on unmount
          return () => {
            Array.from(elements).forEach((element) => {
              element.removeEventListener('click', element._clickHandler);
            });
          };
        }
      }
    };

    const { width, height } = dimensions;
    if (width > 0 && height > 0) {
      drawWordCloud(dataWords, width, window.innerHeight);
    }

    setWordCount(0);
    setAllUserWordCount(0);
    setAllWorldWordCount(0);
    setTotalWorldWordTextCount(0);
    setLastWordUsedWorldDetails("");
    setCountry("");
    setRegion("");
    setTown("");
    setGenderAge("");

    return () => {
      document.removeEventListener('mousedown', closePopupIfClickedOutside);
    }
  }, [dataWords, dimensions]);

  return (
    dataWords.length > 0 ? (
      <Col>
        <div ref={wordCloudContainerRef}>
          <Row>
            <div ref={cloudRef} className={cloudRefClassName}>
              <svg ref={svgRef}></svg>
              {/* Popup */}
              {popup.show && (
                <div className="popup roundedCornersWithBorder" style={{ position: 'absolute', top: popup.y, left: popup.x, backgroundColor: '#eee', color: '#111', padding: '20px' }}>
                  <div style={{ width: "100%", color: "#111" }}>
                    Word: {popup.wordText}<br />Count: {popup.wordCount}
                  </div>
                  <div style={{ width: "100%", marginTop: "20px" }}>
                    <span ref={moreDetailsRef} className="underline" style={{ color: '#111', cursor: 'pointer' }} onClick={() => handleMoreDetails(popup.wordText)}>more details</span>
                  </div>
                </div>
              )}
            </div>
          </Row>
          <Row>
            <div ref={moreDetailsDivRef} className={moreDetailsClassName}>
              <Col>
                <Row xs="12" className="underline clickable">
                  <h2 onClick={closeDetailsDiv}>close details</h2>
                </Row>
                <Row>
                  <Col xs="12" sm="6">
                    <Row>
                      <h3>{moreDetailsHeader}</h3>
                    </Row>
                    <Row style={{ height: '400px' }}>
                      <DataGrid rows={rows} columns={columns} />
                    </Row>
                  </Col>
                  <Col xs="12" sm="6">
                    <Row>
                      <h3>{statsHeader}</h3>
                    </Row>
                    <Row style={{ height: '400px', borderColor: '#fff', borderWidth: '1px', borderStyle: 'solid' }}>
                      <Col xs="6" style={{borderRightWidth: '1px', borderRightColor: '#fff', borderRightStyle: 'solid'}}>
                        <Row>
                          <Col xs="12">
                            <Row style={{ borderBottomWidth: '1px', borderBottomColor: '#fff', borderBottomStyle: 'solid' }}>
                              <h2>You</h2>
                            </Row>
                            <Row>
                              <Col xs="9">
                                Count
                              </Col>
                              <Col xs="3">
                                {wordCount}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="9">
                                Total Word Count
                              </Col>
                              <Col xs="3">
                                {allUserWordCount}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="9">
                                Word Occurs
                              </Col>
                              <Col xs="3">
                                {Math.round((wordCount / allUserWordCount) * 100)}%
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="6">
                        <Row>
                          <Col xs="12">
                            <Row style={{ borderBottomWidth: '1px', borderBottomColor: '#fff', borderBottomStyle: 'solid' }}>
                              <h2>World</h2>
                            </Row>
                            <Row>
                              <Col xs="9">
                                Count
                              </Col>
                              <Col xs="3">
                                {totalWorldWordTextCount}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="9">
                                Total Word Count
                              </Col>
                              <Col xs="3">
                                {allWorldWordCount}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="9">
                                Word Occurs
                              </Col>
                              <Col xs="3">
                                {Math.round((totalWorldWordTextCount / allWorldWordCount) * 100)}%
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" style={{ borderTopWidth: '1px', borderTopColor: '#fff', borderTopStyle: 'solid' }}>
                        <Row className="mt-2">
                          <Col xs="12">
                            <h4>Word last posted on the {lastWordUsedWorldDetails}</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            {country && <h4>{country}</h4>}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            {region && <h4>{region}</h4>}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            {town && <h4>{town}</h4>}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12">
                            {genderAge && <h4>{genderAge}</h4>}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </div>
          </Row>
        </div>
      </Col>
      ) : <Col>
            <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <h1 className="errorMessage">Word Cloud is currently empty</h1>
            </div>
          </Col >
  )
};

export default WordCloud;

//<div className="col-lg-6">
//  <div style={{ width: '95%', marginLeft: '5%' }}>
//    <h3>{statsHeader}</h3>
//  </div>
//  <div style={{ width: '95%', marginLeft: '5%', height: 400, borderColor: '#fff', borderWidth: '1px', borderStyle: 'solid' }}>
//    <div style={{ display: 'flex', width: '100%' }}>
//      <div style={{ width: '50%' }}>
//        <div style={{ width: '100%' }}>
//          <h2>You</h2>
//        </div>
//        <div style={{ width: '100%' }}>
//          <div style={{ width: '40%', display: 'inline-flex' }}>
//            Count
//          </div>
//          <div style={{ width: '60%', display: 'inline-flex' }}>
//            {wordCount}
//          </div>
//        </div>
//        <div style={{ width: '100%' }}>
//          <div style={{ width: '40%', display: 'inline-flex' }}>
//            Total Word Count
//          </div>
//          <div style={{ width: '60%', display: 'inline-flex' }}>
//            {allUserWordCount}
//          </div>
//        </div>
//        <div style={{ width: '100%' }}>
//          <div style={{ width: '40%', display: 'inline-flex' }}>
//            Word Occurs (%)
//          </div>
//          <div style={{ width: '60%', display: 'inline-flex' }}>
//            {Math.round((wordCount / allUserWordCount) * 100)}%
//          </div>
//        </div>
//      </div>
//      <div style={{ width: '50%' }}>
//        <div style={{ width: '100%' }}>
//          <h2>World</h2>
//        </div>
//        <div style={{ width: '100%' }}>
//          <div style={{ width: '40%', display: 'inline-flex' }}>
//            Count
//          </div>
//          <div style={{ width: '60%', display: 'inline-flex' }}>
//            {totalWorldWordTextCount}
//          </div>
//        </div>
//        <div style={{ width: '100%' }}>
//          <div style={{ width: '40%', display: 'inline-flex' }}>
//            Total Word Count
//          </div>
//          <div style={{ width: '60%', display: 'inline-flex' }}>
//            {allWorldWordCount}
//          </div>
//        </div>
//        <div style={{ width: '100%' }}>
//          <div style={{ width: '40%', display: 'inline-flex' }}>
//            Word Occurs (%)
//          </div>
//          <div style={{ width: '60%', display: 'inline-flex' }}>
//            {Math.round((totalWorldWordTextCount / allWorldWordCount) * 100)}%
//          </div>
//        </div>
//      </div>
//    </div>
//    <div style={{ width: '100%', marginTop: '40px', borderTop: 'solid 1px #fff', justifyContent: 'center' }}>
//      <div style={{ width: '100%', textAlign: 'center' }}><h4>Word last posted on the {lastWordUsedWorldDetails}</h4></div>
//      <div style={{ width: '100%', textAlign: 'center' }}>{country && <h4>{country}</h4>}</div>
//      <div style={{ width: '100%', textAlign: 'center' }}>{region && <h4>{region}</h4>}</div>
//      <div style={{ width: '100%', textAlign: 'center' }}>{town && <h4>{town}</h4>}</div>
//      <div style={{ width: '100%', textAlign: 'center' }}>{genderAge && <h4>{genderAge}</h4>}</div>
//    </div>
//  </div>
//</div>