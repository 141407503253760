import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import axios from 'axios';
import { CountryDropdown } from 'react-country-region-selector'

function CountryFilterDropdown({ onChange }) {
  const [country, setCountry] = useState('worldwide');

  const handleCountryFilterChange = (event) => {
    const value = event;
    setCountry(value);
    onChange(value);
  };

  const styles = {
    marginBottom: "1.5rem",
    errorColor: "red",
    appearanceAuto: "auto", // overrides dropdowns not having down arrow
    dropdownColour: "#777" // overrides dropdowns font colour
  };

  return (
    <div>
      <Row>
        <Col sm="3">Country</Col>
        <Col sm="9">
          <CountryDropdown value={country} onChange={handleCountryFilterChange} id="country" name="country" className="form-control" defaultOptionLabel="Select Country" style={{ appearance: styles.appearanceAuto, color: styles.dropdownColour }} />
        </Col>
      </Row>
    </div>
  );
}

export default CountryFilterDropdown;