import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'
import { useForm } from "react-hook-form"
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../config'

export function Login(props) {
  const { register, getValues, watch, handleSubmit, formState: { errors }, clearErrors } = useForm();

  const [data, setdata] = useState({ email: '', password: '' })

  const navigate = useNavigate();

  // vs debug
  const apiUrl = config.loginBaseURL;
  /*  const apiUrl = "https://localhost:8001/api/login/";*/

  const [errorMessage, setErrorMessage] = useState('');

  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const [failedVerification, setFailedVerification] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailConfirmedParam = searchParams.get('emailConfirmed');
    if (emailConfirmedParam && emailConfirmedParam.toLowerCase() === 'true') {
      setEmailConfirmed(true);
    }
    const invalidTokenParam = searchParams.get('invalidToken');
    if (invalidTokenParam && invalidTokenParam.toLowerCase() === 'true') {
      setInvalidToken(true);
    }
    const failedVerificationParam = searchParams.get('failedVerification');
    if (failedVerificationParam && failedVerificationParam.toLowerCase() === 'true') {
      setFailedVerification(true);
    }
  }, [location]);

  const styles = {
    marginBottom: "1.5rem",
    errorColor: "red",
    appearanceAuto: "auto", // overrides dropdowns not having down arrow
    dropdownColour: "#777" // overrides dropdowns font colour
  };

  const onChange = (e) => {
    e.persist();
    //debugger;
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = (event) => {
    event.preventDefault();

    const data1 = { email: data.email, password: data.password };
    axios.post(apiUrl + "Authenticate", data1)
      .then((result) => {

        localStorage.setItem('authToken', result.data.token);
        localStorage.setItem('userID', result.data.userID);
        localStorage.setItem('forename', result.data.forename);
        localStorage.setItem('surname', result.data.surname);
        localStorage.setItem('verified', result.data.verified);
        localStorage.setItem('email', result.data.email);

        const data = result.data;

        if (result.data.Status === 'Invalid') {
          alert('Invalid User');
        }
        else if (result.data.Status === 'ExistingUser') {
          alert('Email address already registered');
        }
        else {
          navigate('/Dashboard', { state: { data } });
        }
      })
      .catch((error) => {
        if (error.response) {
          // The request was made, and the server responded with a status code outside the range of 2xx
          if (error.response.data.status === 'InvalidPassword' || error.response.data.status === 'InvalidEmail') {
            setErrorMessage('Incorrect login details');
          } else {
            // Handle other bad request errors here
          }
        } else if (error.request) {
          // The request was made, but no response was received
          setErrorMessage('An error occured. Please try again');
        } else {
          // Something happened in setting up the request that triggered an Error
          setErrorMessage('An error occured. Please try again');
        }
      });
  };

  return (
    <div className="col-lg-8 offset-lg-2">
      <div className="card o-hidden border-0 shadow-lg my-5" style={{ "marginTop": "5rem" }}>
        <div className="card-body p-0">
          <div className="row">
            <div className="col-lg-12 mb-1 text-center">
              {emailConfirmed && (
                <div className="alertSuccess" role="alert">
                  Email successfully confirmed! You can now log in.
                </div>
              )}
              {invalidToken && (
                <div className="errorMessage" role="alert">
                  There was a problem validating your email address. Please try again.<br />
                  If the problem persists, please let us know by emailing us at admin@my3words.life.
                </div>
              )}
              {failedVerification && (
                <div className="errorMessage" role="alert">
                  Verification failed. This may be because you have already completed the process. Log into your account to see your status.<br />
                  If it says unverified then please email us at admin@my3words.life to report the problem.
                </div>
              )}
            </div>
            <div className="col-lg-12">
              <div className="p-5">
                <div className="text-center">
                  <h1 className="h4 text-black mb-4">Please login below</h1>
                </div>
                <form onSubmit={onSubmit}>
                  <div className="form-group row" style={{ marginBottom: styles.marginBottom }}>
                    <label htmlFor="email">Email</label>
                    <input {...register("email", { required: true })} type="text" id="email" name="email" value={data.email} onChange={onChange} />
                    {errors.email && errors.email.type === "required" && <span style={{ color: styles.errorColor }}>Email is required</span>}
                  </div>
                  <div className="form-group row" style={{ marginBottom: styles.marginBottom }}>
                    <label htmlFor="password">Password</label>
                    <input {...register("password", { required: true })} type="password" id="password" name="password" value={data.password} onChange={onChange} />
                    {errors.password && errors.password.type === "required" && <span style={{ color: styles.errorColor }}>Password field is required</span>}
                  </div>
                  <button type="submit" className="btn btn-main btn-block">Login</button>
                  <div className="form-group row" style={{ marginBottom: styles.marginBottom }}>
                    {errorMessage && <p className="error-message">{errorMessage}</p>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login