import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import DayFilterDropdown from './DayFilterDropdown';
import CountryFilterDropdown from './CountryFilterDropdown';
import CountyFilterDropdown from './CountyFilterDropdown';
import TownFilterDropdown from './TownFilterDropdown';
import GenderFilterDropdown from './GenderFilterDropdown';
import AgeFilterSlider from './AgeFilterSlider';
import { Col, Row, Collapse } from 'reactstrap';

const Filters = ({ handleDaysDropdownChange, handleCountryDropdownChange, handleRegionDropdownChange, selectedCountry, handleTownDropdownChange, selectedRegion, handleGenderDropdownChange, handleAgeChange, handleAgeChangeCommitted }) => {
  return (
    <Col>
      <Row className="ml-1">
        <DayFilterDropdown onChange={handleDaysDropdownChange} />
      </Row>
      <hr />
      <Row className="mt-4">
        <CountryFilterDropdown onChange={handleCountryDropdownChange} />
      </Row>
      <Row className="mt-4">
        <CountyFilterDropdown onChange={handleRegionDropdownChange} country={selectedCountry} />
      </Row>
      <Row className="mt-4">
        <TownFilterDropdown onChange={handleTownDropdownChange} country={selectedCountry} region={selectedRegion} />
      </Row>
      <hr />
      <Row className="mt-4">
        <GenderFilterDropdown onChange={handleGenderDropdownChange} />
      </Row>
      <hr />
      <Row className="mt-4">
        <AgeFilterSlider onChange={handleAgeChange} onChangeCommitted={handleAgeChangeCommitted} />
      </Row>
    </Col>
  );
}

export default Filters