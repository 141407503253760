import React, { useState, useRef } from 'react';
import { Col, Row, Collapse } from 'reactstrap';
import WordList from './WordList';
import Filters from './Filters';

const Home = (args) => {
  localStorage.clear();

  const [selectedDays, setSelectedDays] = useState(60);
  const [selectedCountry, setSelectedCountry] = useState("worldwide");
  const [selectedRegion, setSelectedRegion] = useState("notSelected");
  const [town, setTown] = useState("noTown");
  const [gender, setGender] = useState("b");
  let [ageRange, setAgeRange] = useState([0, 100]);
  const [widthOffset, setWidthOffset] = useState(window.innerWidth <= 768 ? 0 : 360);
  const [heightOffset, setHeightOffset] = useState(window.innerWidth <= 768 ? 0 : 260);

  //const [isFiltersLinkDisplayed, setIsFiltersLinkDisplayed] = useState("inline");

  const handleDaysDropdownChange = (value) => {
    setSelectedDays(value);
  };

  const handleCountryDropdownChange = (value) => {
    if (value == "") {
      value = "worldwide";
    }

    setSelectedCountry(value);
  };

  const handleRegionDropdownChange = (value) => {
    if (value == "") {
      value = "notSelected";
    }

    setSelectedRegion(value);
  };

  const handleTownDropdownChange = (value) => {
    if (value != "Enter Town (after Region)") {
      setTown(value);
    }
  };

  const handleGenderDropdownChange = (value) => {
    if (value != "b") {
      setGender(value);
    }
  };

  const handleAgeChange = (value) => {
    ageRange = value;
  };

  const handleAgeChangeCommitted = (value) => {
    setAgeRange(value);
  };

  const [isWordsOpen, setIsWordsOpen] = useState(true);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const toggle = () => {
    setIsWordsOpen(!isWordsOpen);
    setIsFiltersOpen(!isFiltersOpen);
  }

  const reset = () => {
    setSelectedDays(60);
    setSelectedCountry("worldwide");
    setSelectedRegion("notSelected");
  }

  return (
    <Row className="mt-5">
      <Col xs="12" sm="2">
        <Collapse isOpen={isWordsOpen} {...args}>
          <Row>
            <Col>
              <p style={{ "marginTop": "50px" }}>Seems too simple doesn't it?</p>
              <p>Just 3 words to describe your current thinking.<br />The events going on in your life.<br />How you're feeling.<br />Where you are.<br />What you're doing.<br />What you're buying.</p>
              <p>Anything. And everything.</p>
              <p>Just so long as it's just 3 words.</p>
              <p>Sometimes it's the simple things in life which tell a story.</p>
              <p>Twice a day, you can enter YOUR 3 words.</p>
              <p>To build a picture of YOU over time.</p>
              <p>And using your location, add to the collective feeling of your town, region or even country!</p>
              <p>So.......what 3 words are you going to write?</p>
            </Col>
          </Row>
        </Collapse>
        <Row>
          <Col xs="12" sm="6">
            <p>Filters</p>
          </Col>
          <Col xs="12" sm="6" className="alignRight clickable">
            <span className="underline" onClick={toggle}>{isFiltersOpen ? 'hide' : 'show'}</span>
          </Col>
          <Col xs="12" sm="12" className="alignRight clickable">
            <span className="underline" onClick={reset}>reset</span>
          </Col>
        </Row> 
        <Collapse isOpen={isFiltersOpen} {...args}>
          <hr style={{marginTop: "0px"}} />
          <Row className="mt-5">
            <Filters
              handleDaysDropdownChange={handleDaysDropdownChange}
              handleCountryDropdownChange={handleCountryDropdownChange}
              handleRegionDropdownChange={handleRegionDropdownChange}
              selectedCountry={selectedCountry}
              handleTownDropdownChange={handleTownDropdownChange}
              selectedRegion={selectedRegion}
              handleGenderDropdownChange={handleGenderDropdownChange}
              gender={gender}
              handleAgeChange={handleAgeChange}
              handleAgeChangeCommitted={handleAgeChangeCommitted}
            />
          </Row>
        </Collapse>
      </Col>
      <Col xs="12" sm="10" className="mt-3 text-center">
        <h3>The top 100 words for {selectedRegion != "notSelected" ? selectedRegion + "," : ""} {selectedCountry != "worldwide" ? selectedCountry : selectedCountry} in the last {selectedDays} days</h3>
        <WordList numDays={selectedDays} countryID={selectedCountry} regionID={selectedRegion} town={town} gender={gender} ageRange={ageRange} widthOffset={widthOffset} heightOffset={heightOffset} />
      </Col>
    </Row>
  );
};

export default Home;