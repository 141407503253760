import axios from 'axios';

export default class Helper {
  static removeClassAndAddNew(classArray, classToRemove, newClass) {
    let classNamesArray = classArray.split(' ');

    // Filter out the classToRemove from the array
    let updatedClassNamesArray = classNamesArray.filter(className => className !== classToRemove);

    // Add the new class to the array
    updatedClassNamesArray.push(newClass);

    // Join the array back into a string and update the state
    let updatedClassName = updatedClassNamesArray.join(' ');

    return updatedClassName;
  }

  static async checkWord(word) {
    let itemPassed = "";

    let rapidApiUrl = 'https://wordsapiv1.p.rapidapi.com/words/' + word + '/pertainsTo';

    try {
      const response = await axios.get(rapidApiUrl, {
        headers: {
          'content-type': 'application/octet-stream',
          'X-RapidAPI-Key': 'de3a33944amsh1803bc40d48d3b6p118fe2jsn94a208d09e50',
          'X-RapidAPI-Host': 'wordsapiv1.p.rapidapi.com'
        },
      });

      if (response.status === 200) {
        itemPassed = "passed";
      } else if (response.status === 404) {
        itemPassed = "failed";
      } else {
        // Handle other status codes if needed
      }
    } catch (error) {
      itemPassed = "failed";
    }

    return itemPassed;
  }

  static convertToPrettyDate(dateToConvert) {
    // Convert the string to a JavaScript date object
    const parts = dateToConvert.split("/");
    const formattedDate = new Date(parts[2], parts[1] - 1, parts[0]);

    // Use toLocaleDateString to format the date
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    return formattedDate.toLocaleDateString('en-GB', options);
  }
}