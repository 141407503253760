import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import axios from 'axios';
import { RegionDropdown } from 'react-country-region-selector'

function CountyFilterDropdown({ onChange, country }) {
  const [region, setRegion] = useState('');

  const handleRegionFilterChange = (event) => {
    const value = event;
    setRegion(value);
    onChange(value);
  };

  const styles = {
    marginBottom: "1.5rem",
    errorColor: "red",
    appearanceAuto: "auto", // overrides dropdowns not having down arrow
    dropdownColour: "#777" // overrides dropdowns font colour
  };

  return (
    <div>
      <Row>
        <Col sm="3">Region</Col>
        <Col sm="9">
          <RegionDropdown country={country} value={region} onChange={handleRegionFilterChange} id="county" name="county" className="form-control" defaultOptionLabel="Select (after Country)" style={{ appearance: styles.appearanceAuto, color: styles.dropdownColour }} />
        </Col>
      </Row>
    </div>
  );
}

export default CountyFilterDropdown;