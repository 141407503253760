import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import axios from 'axios';
import Helper from '../helper';
import config from '../config'

export function WordForm(props) {
  const { register, getValues, watch, handleSubmit, formState: { errors }, clearErrors } = useForm();

  const inputBorders = props.tod === "am" ? "blueInput" : "redInput";
  const inputText = props.tod === "am" ? "blueText" : "redText";
  const inputBG = props.tod === "am" ? "blueBG" : "redBG";

  // vs debug
  const apiUrl = config.wordsBaseURL;
  /*  const apiUrl = "https://localhost:7174/api/words/";*/

  const bgColour = {
    am: "#a8dadc",
    pm: "#e63946"
  };

  const styles = {
    marginBottom: "1.5rem",
    marginTop: "2rem"
  };

  const [data, setdata] = useState({ word1: '', word2: '', word3: '' })
  const [errorMessage, setErrorMessage] = useState('');

  const onChange = (e) => {
    e.persist();
    //debugger;
    setdata({ ...data, [e.target.name]: e.target.value });
  };

  const onCbClick = (e) => {
    if (e.target.checked) {
      alert("By bypassing the spell check, you agree to enter a word which is still relevant to you such as a name, business, location or suchlike. You also agree that the word to be entered is not offensive in any way. This includes and is not limited to offensive words linked to swearing, racism, sexism, gender, etc. Any such words entered will result in a lifetime ban from My3Words.")
    }
  }

  const [errorMessage1, setErrorMessage1] = useState('');
  const [errorMessage2, setErrorMessage2] = useState('');
  const [errorMessage3, setErrorMessage3] = useState('');
  const [errorMessage4, setErrorMessage4] = useState('');

  const handleCancel = () => {
    if (props.tod === "am") {
      props.onCancelClick("am");
    }
    else {
      props.onCancelClick("pm");
    }
  }

  const word1CB = useRef(false);
  const word2CB = useRef(false);
  const word3CB = useRef(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    let result = "";
    let i = 1;
    let failed = false;

    const fetchData = async (word) => {
      // no spaces
      if (word.includes(" ")) {
        setErrorMessage1('Only single words allowed.');
        failed = true;
      }

      // only single hyphenated words allowed
      if (word.includes("-")) {
        setErrorMessage1("Sorry, we don't allow hyphenated words due to the potential for misuse.");
        failed = true;
      }

      // only words up to 10 characters allowed
      if (word.length > 15) {
        setErrorMessage1("Sorry, we only allow words up to 15 characters in length. This is to ensure the word cloud is displayed correctly and uncluttered.");
        failed = true;
      }

      result = await Helper.checkWord(word);
      if (i === 1 && result === "failed") {
        setErrorMessage1('Word not recognised in English dictionary');
        failed = true;
      }
      if (i === 2 && result === "failed") {
        setErrorMessage2('Word not recognised in English dictionary');
        failed = true;
      }
      if (i === 3 && result === "failed") {
        setErrorMessage3('Word not recognised in English dictionary');
        failed = true;
      }

      i = i + 1;
    };

    if (!(word1CB.current.checked)) {
      result = await fetchData(data.word1);
    }

    if (!(word2CB.current.checked)) {
    result = await fetchData(data.word2);
    }

    if (!(word3CB.current.checked)) {
      result = await fetchData(data.word3);
    }

    if (failed === false) {
      const token = localStorage.getItem('authToken');
      const userID = localStorage.getItem('userID');

      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');

      const timeString = `${hours}:${minutes}:${seconds}`;

      const data1 = { userID: userID, entryDate: new Date(), entryPeriod: props.tod, word1: data.word1.toLowerCase(), word2: data.word2.toLowerCase(), word3: data.word3.toLowerCase(), entryTime: timeString };

      axios.post(apiUrl + "AddWords", data1, {
        headers: {
          Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          const data = result.data;

          props.onUpdate(data);
        })
        .catch((error) => {
          console.log(error);
          if (error.response) {
            // The request was made, and the server responded with a status code outside the range of 2xx
            if (error.response.data.status === 'AlreadyEntered') {
              setErrorMessage4('Words already entered for this time period');
            } else {
              // Handle other bad request errors here
            }
          } else if (error.request) {
            // The request was made, but no response was received
            setErrorMessage('An error occured. Please try again');
          } else {
            // Something happened in setting up the request that triggered an Error
            setErrorMessage('An error occured. Please try again');
          }
        });
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="form-group row" style={{ marginBottom: styles.marginBottom }}>
        <label htmlFor="word1" className={inputText}>Word 1</label>
        <input {...register("word1", { required: true })} type="text" id="word1" name="word1" value={data.word1} onChange={onChange} className={inputBorders} />
        <div>bypass spelling check<input type="checkbox" ref={word1CB} id="word1CB" onClick={onCbClick} style={{ marginLeft: "20px" }} /></div>
        {errors.word1 && errors.word1.type === "required" && <span style={{ color: styles.errorColor }}>Word 1 is required</span>}
        {errorMessage1 && <div className="errorMessage form-group row">{errorMessage1}</div>}
      </div>
      <div className="form-group row" style={{ marginBottom: styles.marginBottom }}>
        <label htmlFor="word2" className={inputText}>Word 2</label>
        <input {...register("word2", { required: true })} type="text" id="word2" name="word2" value={data.word2} onChange={onChange} className={inputBorders} />
        <div>bypass spelling check<input type="checkbox" ref={word2CB} id="word2CB" onClick={onCbClick} style={{ marginLeft: "20px" }} /></div>
        {errors.emword2ail && errors.word2.type === "required" && <span style={{ color: styles.errorColor }}>Word 2 is required</span>}
        {errorMessage2 && <div className="errorMessage form-group row">{errorMessage2}</div>}
      </div>
      <div className="form-group row">
        <label htmlFor="word3" className={inputText}>Word 3</label>
        <input {...register("word3", { required: true })} type="text" id="word3" name="word3" value={data.word3} onChange={onChange} className={inputBorders} />
        <div>bypass spelling check<input type="checkbox" ref={word3CB} id="word3CB" onClick={onCbClick} style={{ marginLeft: "20px" }} /></div>
      </div>
      <div className="form-group row">
        {errors.word3 && errors.word3.type === "required" && <span style={{ color: styles.errorColor }}>Word 3 is required</span>}
        {errorMessage3 && <div className="errorMessage form-group row">{errorMessage3}</div>}
      </div>
      <div className="form-group row" style={{ marginTop: styles.marginTop }}>
        <button type="submit" className={`btn btn-block ${inputBG}`}>Add My 3 Words</button>
        <span className={`clickable ${inputText}`} onClick={handleCancel} >cancel</span>
        {errorMessage4 && <div className="errorMessage form-group row">{errorMessage4}</div>}
      </div>
    </form>
  )
}

export default WordForm