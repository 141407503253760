import * as React from 'react';
import { Col, Row } from 'reactstrap';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
  return `${value}`;
}

export default function AgeFilterSlider({ onChange, onChangeCommitted }) {
  const [ageRange, setAgeRange] = React.useState([0, 120]);

  const handleChange = (event, newValue) => {
    setAgeRange(newValue);
    onChange(newValue);
  };

  const handleAgeChangeCommitted = (event, newValue) => {
    setAgeRange(newValue);
    onChangeCommitted(newValue);
  };

  return (
    <div>
      <Row className="mt-3">
        <Col sm="3">Age</Col>
        <Col sm="9">
          <Box sx={{ width: '91%' }}>
            <Slider
              getAriaLabel={() => 'Age range'}
              value={ageRange}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              valueLabelDisplay="on"
              onChangeCommitted={handleAgeChangeCommitted}
            />
          </Box>
        </Col>
      </Row>
    </div>
  );
}