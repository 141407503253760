import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Calendar from '../components/Calendar';

export function History(props) {
  const location = useLocation();
  const data = location.state?.data;

  return (
    <div className="col-lg-12">
      <div className="o-hidden border-0 shadow-lg my-5" style={{ "marginTop": "5rem" }}>
        <div className="p-0">
          <div className="row">
            <div className="col-lg-12">
              <h1>Word History</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Calendar />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default History