import React, { useState } from "react";
import "../App.css";
import Filters from './Filters';

function SlideDrawer(props) {
  let drawerClasses = props.show ? "side-drawer open" : "side-drawer";

  const [selectedDays, setSelectedDays] = useState(60);
  const [selectedCountry, setSelectedCountry] = useState("worldwide");
  const [selectedRegion, setSelectedRegion] = useState("notSelected");
  const [town, setTown] = useState("none");
  const [gender, setGender] = useState("");
  const [ageRange, setAgeRange] = useState("");

  const handleDaysDropdownChange = (value) => {
    setSelectedDays(value);
    props.handleDaysDropdownChange(value);
  };

  const handleCountryDropdownChange = (value) => {
    if (value == "") {
      value = "worldwide";
    }

    setSelectedCountry(value);
    props.handleCountryDropdownChange(value);
  };

  const handleRegionDropdownChange = (value) => {
    if (value == "notSelected") {
      value = "";
    }

    setSelectedRegion(value);
    props.handleRegionDropdownChange(value);
  };

  const handleTownDropdownChange = (value) => {
    if (value != "Enter Town (after Region)") {
      setTown(value);
      props.handleTownDropdownChange(value);
    }
  };

  const handleGenderDropdownChange = (value) => {
    if (value != "b") {
      setGender(value);
      props.handleGenderDropdownChange(value);
    }
  };

  const handleAgeChange = (value) => {
    setAgeRange(value);
    props.handleAgeChange(value);
  };

  const handleAgeChangeCommitted = (value) => {
    setAgeRange(value);
    props.handleAgeChangeCommitted(value);
  };

  return (
    <div className={drawerClasses}>
      <h1 style={{ color: "white" }}>Filters</h1>
      <hr style={{ color: "#fff", backgroundColor: "#fff", height: "2px", opacity: 1 }} />
      <Filters
        handleDaysDropdownChange={handleDaysDropdownChange}
        handleCountryDropdownChange={handleCountryDropdownChange}
        handleRegionDropdownChange={handleRegionDropdownChange}
        selectedCountry={selectedCountry}
        handleTownDropdownChange={handleTownDropdownChange}
        selectedRegion={selectedRegion}
        handleGenderDropdownChange={handleGenderDropdownChange}
        gender={gender}
        handleAgeChange={handleAgeChange}
        handleAgeChangeCommitted={handleAgeChangeCommitted}
      />
    </div>
  );
};

export default SlideDrawer;
