import React, { useState, useEffect } from 'react';
import Helper from '../helper';
import { WordForm } from './WordForm';
import axios from 'axios';
import config from '../config'

export function AddWords(props) {
  const [errorMessage, setErrorMessage] = useState('');

  const [loadAmClassName, setLoadAmClassName] = useState("col-lg-4 justify-content-center align-items-center d-flex");
  const [loadAmClassNameForm, setLoadAmClassNameForm] = useState("col-lg-4 justify-content-center align-items-center hiddenDiv");
  const [loadAmClassNameDone, setLoadAmClassNameDone] = useState("col-lg-4 justify-content-center align-items-center hiddenDiv");
  const [loadPmClassName, setLoadPmClassName] = useState("col-lg-4 justify-content-center align-items-center d-flex");
  const [loadPmClassNameForm, setLoadPmClassNameForm] = useState("col-lg-4 justify-content-center align-items-center hiddenDiv");
  const [loadPmClassNameDone, setLoadPmClassNameDone] = useState("col-lg-4 justify-content-center align-items-center hiddenDiv");

  const [word1am, setWord1am] = useState("");
  const [word2am, setWord2am] = useState("");
  const [word3am, setWord3am] = useState("");
  const [word1pm, setWord1pm] = useState("");
  const [word2pm, setWord2pm] = useState("");
  const [word3pm, setWord3pm] = useState("");

  // vs debug
  const apiUrl = config.wordsBaseURL;
  /*  const apiUrl = "https://localhost:7174/api/words/";*/

  const token = localStorage.getItem('authToken');
  const userID = localStorage.getItem('userID');

  const data1 = { userID: userID };

  useEffect(() => {
    checkTodaysEntries();
  }, []);

  const bgColour = {
    am: "#a8dadc",
    pm: "#e63946"
  };

  const styles = {
    marginBottom: "1.5rem"
  };

  const handleAmClick = () => {
    setLoadAmClassName(Helper.removeClassAndAddNew(loadAmClassName, "d-flex", "hiddenDiv"));
    setLoadAmClassNameForm(Helper.removeClassAndAddNew(loadAmClassName, "hiddenDiv", "d-flex"));
  }

  const handlePmClick = () => {
    setLoadPmClassName(Helper.removeClassAndAddNew(loadPmClassName, "d-flex", "hiddenDiv"));
    setLoadPmClassNameForm(Helper.removeClassAndAddNew(loadPmClassName, "hiddenDiv", "d-flex"));
  }

  const handleCancelClick = (ampm) => {
    if (ampm == "am") {
      setLoadAmClassName(Helper.removeClassAndAddNew(loadPmClassName, "hiddenDiv", "d-flex"));
      setLoadAmClassNameForm(Helper.removeClassAndAddNew(loadPmClassName, "d-flex", "hiddenDiv"));
    }
    else {
      setLoadPmClassName(Helper.removeClassAndAddNew(loadPmClassName, "hiddenDiv", "d-flex"));
      setLoadPmClassNameForm(Helper.removeClassAndAddNew(loadPmClassName, "d-flex", "hiddenDiv"));
    }
  }

  const checkTodaysEntries = () => {
    axios.get(apiUrl + "CheckTodaysEntry", {
      params: data1,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((result) => {
      const data = result.data.split(",");

      if (data !== "") {
        if (data[0] === "am") {
          setLoadAmClassName(Helper.removeClassAndAddNew(loadAmClassName, "d-flex", "hiddenDiv"));
          setLoadAmClassNameDone(Helper.removeClassAndAddNew(loadAmClassName, "hiddenDiv", "d-flex"));

          setWord1am(data[1]);
          setWord2am(data[2]);
          setWord3am(data[3]);
        }

        if (data[4] === "pm") {
          setLoadPmClassName(Helper.removeClassAndAddNew(loadAmClassName, "d-flex", "hiddenDiv"));
          setLoadPmClassNameDone(Helper.removeClassAndAddNew(loadAmClassName, "hiddenDiv", "d-flex"));

          setWord1pm(data[5]);
          setWord2pm(data[6]);
          setWord3pm(data[7]);
        }
      }
    })
    .catch((error) => {
      console.log(error);
      if (error.response) {
        // The request was made, but no response was received
        setErrorMessage('An error occured. Please try again');
      } else {
        // Something happened in setting up the request that triggered an Error
        setErrorMessage('An error occured. Please try again');
      }
    });
  }

  const handleUpdate = (updatedData) => {
    setLoadAmClassNameForm(Helper.removeClassAndAddNew(loadAmClassName, "d-flex", "hiddenDiv"));
    setLoadPmClassNameForm(Helper.removeClassAndAddNew(loadAmClassName, "d-flex", "hiddenDiv"));

    checkTodaysEntries();
  };

  return (
    <div className="col-lg-12">
      <div className="o-hidden border-0 shadow-lg my-5" style={{ "marginTop": "5rem" }}>
        <div className="p-0">
          <div className="row" style={{marginBottom: styles.marginBottom}}>
            <div className="col-lg-12">
              <h1>Add Words</h1>
            </div>
            <div className="col-lg-12">
              <h3>Please enter your 3 words for today. Once for the morning and once for the afternoon.</h3>
              <h5>Note: these can be done seperately during the course of the day.</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-1">
            </div>
            <div onClick={handleAmClick} className={loadAmClassName} style={{ backgroundColor: bgColour.am, height: "50vh", verticalAlign: "middle", cursor: "pointer" }}>
              <h5>MORNING</h5>
            </div>
            <div className={loadAmClassNameForm} style={{ borderColor: bgColour.am, borderWidth: "2px", borderStyle: "solid", height: '50vh', verticalAlign: 'middle' }}>
              <WordForm tod="am" onUpdate={handleUpdate} />
            </div>
            <div className={loadAmClassNameDone} style={{ borderColor: bgColour.am, borderWidth: "2px", borderStyle: "solid", height: '50vh', verticalAlign: 'middle', textAlign: 'center' }}>
              My3Words entered for this morning<br />
              {word1am}<br />
              {word2am}<br />
              {word3am}
            </div>
            <div className="col-lg-2">
            </div>
            <div onClick={handlePmClick} className={loadPmClassName} style={{ backgroundColor: bgColour.pm, height: "50vh", verticalAlign: "middle", cursor: "pointer" }}>
              <h5>AFTERNOON</h5>
            </div>
            <div className={loadPmClassNameForm} style={{ borderColor: bgColour.pm, borderWidth: "2px", borderStyle: "solid", height: '50vh', verticalAlign: 'middle' }}>
              <WordForm tod="pm" onUpdate={handleUpdate} onCancelClick={handleCancelClick} />
            </div>
            <div className={loadPmClassNameDone} style={{ borderColor: bgColour.pm, borderWidth: "2px", borderStyle: "solid", height: '50vh', verticalAlign: 'middle', textAlign: 'center' }}>
              My3Words entered for this afternoon<br />
              {word1pm}<br />
              {word2pm}<br />
              {word3pm}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddWords