import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const LogoutAfterInactivity = () => {
  const navigate = useNavigate();
  const timeoutRef = useRef(null);
  const user = localStorage.getItem('userID');

  if (user === null) {
    navigate('/login');
  }

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      console.log("userID=" + user);
      if (user === null) {
        navigate('/login');
      }
    }, 300000); // 5 minutes
  };

  useEffect(() => {
    document.addEventListener('mousemove', resetTimeout);
    document.addEventListener('keypress', resetTimeout);

    return () => {
      document.removeEventListener('mousemove', resetTimeout);
      document.removeEventListener('keypress', resetTimeout);
    };
  }, []);

  return (
    <span></span>
  );
};

export default LogoutAfterInactivity;