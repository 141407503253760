import React from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';

// ... rest of the code ...

const Navigation = () => {
  return (
    <Navbar color="dark" dark expand="md">
      <NavbarBrand href="/">My3Words</NavbarBrand>
      <Nav className="mr-auto" navbar>
        <NavItem>
          <NavLink href="/Home">Home</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/Register">Register</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/Login">Login</NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  );

 
};

export default Navigation;
