import Navigation from './Navigation'
import React from 'react';
import { Container } from 'reactstrap';

const Layout = ({children}) => {
  return (
      <Container fluid className="bg">
        <Navigation />
        <main>{children}</main>
      </Container>
  )
}

export default Layout
