import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

function GenderFilterDropdown({ onChange }) {
  const [selectedGender, setSelectedGender] = useState('');

  const handleGenderDropdownChange = (event) => {
    const value = event.target.value;
    setSelectedGender(value);
    onChange(value);
  };

  const styles = {
    appearanceAuto: "auto", // overrides dropdowns not having down arrow
    dropdownColour: "#777" // overrides dropdowns font colour
  };

  return (
    <div>
      <Row>
        <Col sm="3">Gender</Col>
        <Col sm="9">
          <select value={selectedGender} className="form-control" onChange={handleGenderDropdownChange} style={{ appearance: styles.appearanceAuto, color: styles.dropdownColour }}>
            <option value="b">Select Gender</option>
            <option value="m">Male</option>
            <option value="f">Female</option>
            <option value="n">Non-Binary</option>
          </select>
        </Col>
      </Row>
    </div>
  );
}

export default GenderFilterDropdown;
