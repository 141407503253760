import React from 'react';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

// ... rest of the code ...

const NavigationAccount = () => {
  const navigate = useNavigate();

  return (
    <Navbar color="dark" dark expand="md">
      <NavbarBrand href="/Dashboard">My3Words</NavbarBrand>
      <Nav className="mr-auto" navbar>
        <NavItem>
          <NavLink href="/DashBoard">Dashboard</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/AddWords">Add Words</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#" onClick={(e) => {e.preventDefault();navigate('/History');}}>
            History
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/Logout">Logout</NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  );


};

export default NavigationAccount;
