import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DatePickerWrapper(props) {
    const [startDate, setStartDate] = useState(null);
    const maxDate = new Date();

    function handleDateChange(date) {
        setStartDate(date);
        props.onDateSelect(date);
    }

    return (
        <DatePicker selected={startDate} onChange={handleDateChange} peekNextMonth showMonthDropdown showYearDropdown dropdownMode="select" dateFormat="yyyy/MM/dd" maxDate={maxDate} placeholderText="Date of Birth (optional)" />
    );
}

export default DatePickerWrapper;

//const DatePickerWrapper = () => {
//    const [startDate, setStartDate] = useState(new Date());
//    return (
//        <DatePicker
//            selected={startDate}
//            onChange={(date) => setStartDate(date)}
//            peekNextMonth
//            showMonthDropdown
//            showYearDropdown
//            dropdownMode="select"
//        />
//    )
//};

//export default DatePickerWrapper;